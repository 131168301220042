import Modal from 'common/components/Modal/Modal';
import { useTranslation } from 'react-i18next';
import { setCookieMessage } from 'common/utils/localStorage';
import { Link } from 'react-router-dom';
import { useStore } from 'context/Store';

const Cookies = ({ setCookieModal }) => {
  const { t } = useTranslation('cookies');

  const [_, dispatch] = useStore();

  const handleCookies = payload => {
    setCookieMessage('acceptedCookies', payload, 180);
    dispatch({ type: 'SET_COOKIES', payload });
    if (payload) {
      dispatch({ type: 'SET_COOKIES_INTERACTION', payload: true });
    }
  };

  return (
    <Modal>
      <div className="cookies__container">
        <p>
          {t('weUseCookies')}
          <Link to={'/privacy-policy'}>{t('privacyPolicy')}</Link>
          {t('ifYouDecline')}
        </p>

        <div className="cookies__actions">
          <button
            className="cookies__action cookies__action--decline"
            onClick={() => {
              handleCookies(false), setCookieModal(false);
            }}
          >
            {t('Decline')}
          </button>
          <button
            className="cookies__action cookies__action--accept"
            onClick={() => handleCookies(true)}
          >
            {t('Accept')}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default Cookies;
