import { useState, useEffect } from 'react';
import { getArticlesPageData } from 'services/section';
import { getArticlesByTag } from 'services/articles';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import Article from 'common/components/Article/Article';
import LayoutFilters from 'common/components/Layout/LayoutFilters';
import { tagsHandler } from 'common/helpers/tags';
import NoResults from 'common/components/NoResults/NoResults';
import { useGetById } from 'hooks/useGetById';
import Pagination from 'common/components/Pagination/Pagination';
import { useGet } from 'hooks/useGet';
import { getTags } from 'services/tags';
import { capitalize } from 'common/utils/strings';
import ArticleSkeleton from 'common/components/Skeletons/ArticleSkeleton';
import Help from 'common/components/Registration/Help';

const ArticleListPage = () => {
  const [articles, setArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [filterValues, setFilterValues] = useState({
    topics: [],
    search: '',
    sort: '',
  });

  const { t } = useTranslation('layouts');
  const location = useLocation();
  const { slug } = useParams();
  const { data } = useGetById(getArticlesPageData, slug);

  const { data: tags } = useGet(getTags);

  const tagHelper = tagsHandler(tags);

  // items per page
  const pageSize = 20;

  const handleParameters = () => {
    let topics = [];
    let sort = '';
    let search = '';

    let parameters = location.search?.split('?')[1].split('&');

    parameters.length &&
      parameters.map(parameter => {
        if (parameter.includes('topic=')) {
          topics = parameter
            .split('topic=')[1]
            .split(',')
            .map(topic => ({ label: tagHelper[topic], value: topic }));
        }
        if (parameter.includes('sort=')) {
          sort = parameter.split('sort=')[1];
        }
        if (parameter.includes('q=')) {
          search = {
            label: parameter.split('q=')[1],
            value: parameter.split('q=')[1],
          };
        }
      });

    setFilterValues({ topics, sort, search });
  };

  const handlePageData = async () => {
    try {
      setIsLoading(true);
      const articlesList = await getArticlesByTag(
        [data[0].metadata.tags[0].sys.id, ...filterValues.topics.map(topic => topic.value)]
          .filter(tag => tag.length)
          .join(),
        filterValues.search?.value,
        page * pageSize,
        filterValues.sort?.value
      );
      setArticles(articlesList.items);
      setFilteredArticles(articlesList.items);
      setPageCount(Math.ceil(articlesList.total / pageSize));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleDefaultTag = () => {
    if (data?.length) {
      return data[0].metadata.tags.length ? data[0].metadata.tags[0].sys.id : '';
    }
    return 'noTagYet';
  };

  useEffect(() => {
    if (location && location.search && data?.length && data[0].metadata.tags) {
      handleParameters();
    } else {
      setFilterValues({
        topics: [],
        search: '',
        sort: '',
      });
      setPage(0);
      setPageCount(0);
    }
  }, [slug, location, tags]);

  useEffect(() => {
    if (data?.length && slug) {
      handlePageData();
    }
  }, [slug, data, page, filterValues]);

  // ANALYTICS
  useEffect(() => {
    if (data?.length) {
      document.title = `Crowe Global - ${capitalize(data[0].metadata.tags[0].sys.id)} Articles`;
    }
  }, [slug, data, location]);

  return (
    <>
      <div className="layout__container">
        {!!data?.length && <h1 className="layout__title">{data?.[0].fields.title}</h1>}
        <div className="resources__help">
          <Help
            text="Enter text or select from each field below to find what you are looking for."
            icon="fa-solid fa-circle-info"
          />
        </div>
        <LayoutFilters
          values={filterValues}
          setValues={setFilterValues}
          items={articles}
          setFilteredItems={setFilteredArticles}
          searchPlaceholder={t('search')}
          pageSize={pageSize}
          setPageCount={setPageCount}
          sectionTag={handleDefaultTag()}
          page={page}
          setPage={setPage}
          getService={getArticlesByTag}
          sectionIsLoading={isLoading}
        />
        {isLoading && (
          <div className="layout__row mb-20px">
            <div className="layout__article">
              <ArticleSkeleton />
            </div>
            <div className="layout__article">
              <ArticleSkeleton />
            </div>
            <div className="layout__article">
              <ArticleSkeleton />
            </div>
            <div className="layout__article">
              <ArticleSkeleton />
            </div>
          </div>
        )}
        <div className={isLoading ? 'hidden' : ''}>
          {!!articles?.length ? (
            <div>
              {filteredArticles?.length ? (
                <div className="layout__row">
                  {filteredArticles.map(article => (
                    <div key={article.sys.id} className="layout__article">
                      <Article item={article} />
                    </div>
                  ))}
                </div>
              ) : (
                <NoResults icon="icon-search" title={t('noArticles')} comment={t('try')} />
              )}
            </div>
          ) : (
            <NoResults title={t('noArticles')} icon="icon-file-document" comment={t('comeBack')} />
          )}

          {pageCount > 1 && <Pagination total={pageCount} setPage={setPage} activePage={page} />}
        </div>
      </div>
    </>
  );
};

export default ArticleListPage;
