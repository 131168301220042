import { get, put } from 'common/api';
import { uploadFiles } from 'services/upload';
import { getUserId } from 'common/utils/localStorage';

export const getUserById = async id => {
  try {
    const res = await get(`/users/${id}`);
    return res;
  } catch (error) {
    return error;
  }
};

export const getMembers = async (offset, filters = {}, specialties) => {
  let newFilters = { ...filters };
  if (specialties) {
    if (filters.specialty_category && !filters.specialties) {
      newFilters.specialties = specialties
        .filter(specialty => specialty.category_id === filters.specialty_category)
        .map(specialty => specialty.id);
    } else if (filters.specialties) {
      newFilters.specialties = [filters.specialties];
    }
  }
  try {
    return await get(
      `/directory/members?offset=${offset}&filters=${encodeURIComponent(
        JSON.stringify(newFilters)
      )}`
    );
  } catch (err) {
    return err.errors.message;
  }
};

export const updateUser = async (id, params) => {
  const { cv_url, profile_photo_url } = params;
  let files = [];
  let updateParams = params;

  if (cv_url) files.push(cv_url[0]);
  if (profile_photo_url) files.push(profile_photo_url[0]);

  if (files.length) {
    const results = await uploadFiles({ files });
    if (results.length) {
      results.forEach(file => {
        const imgRegexPattern = /image/;
        const fileUrl = file.s3Path || file.path;
        if (imgRegexPattern.test(file.mimetype)) {
          updateParams = Object.assign({}, updateParams, { profile_photo_url: fileUrl });
        } else {
          updateParams = Object.assign({}, updateParams, { cv_url: fileUrl });
        }
      });
    }
  }

  try {
    await put(`/users/${id}`, updateParams);
  } catch (err) {
    throw new Error(err);
  }
};

export const getUser = async id => {
  try {
    return await get(`/users/${id}`);
  } catch (err) {
    return err;
  }
};

export const getCurrentUser = async () => {
  try {
    return await get(`/users/${getUserId()}`);
  } catch (err) {
    return err;
  }
};

export const getPendingUsers = async allUsers => {
  try {
    const queryString = allUsers ? '?allUsers=true' : '';
    return await get(`/users/pending${queryString}`);
  } catch (err) {
    return err;
  }
};

export const approveUser = async id => {
  try {
    await put(`/users/${id}/approve`);
    window.location.reload();
  } catch (err) {
    return err;
  }
};

export const deactivateUser = async id => {
  try {
    await put(`/users/${id}/deactivate`, '');
    return window.location.reload();
  } catch (err) {
    throw new Error(err);
  }
};

export const rejectUser = async id => {
  try {
    return await put(`/users/${id}/reject`);
  } catch (err) {
    return err;
  }
};

export const setRolePrivileges = async (id, roleName) => {
  try {
    return await put(`/users/${id}/setRole?role=${roleName}`);
  } catch (err) {
    return err;
  }
};

export const sendPasswordResetEmail = async id => {
  try {
    return await put(`/users/${id}/resetPassword`, '');
  } catch (err) {
    throw new Error(err);
  }
};
