import axios from 'axios';
import {
  setToken,
  setFirmId,
  setUserId,
  setHyvorHash,
  setHyvorUserData,
  setCookie,
  removeToken,
  removeAdminTab,
  removeFirmId,
  removeUserId,
  removeHyvor,
} from 'common/utils/localStorage';

export const login = async (creds, location, navigate) => {
  try {
    const response = await axios.post(`${process.env.CROWE_BASE_SERVER_URL}/auth/login`, creds);

    const { token, permissions, firmId, userId, hyvorHash, hyvorUserData } = response.data;

    setToken(token);
    setCookie('authToken', token, 180);
    setFirmId(firmId);
    setUserId(userId);
    setHyvorHash(hyvorHash);
    setHyvorUserData(hyvorUserData);
    if (location.state?.from) {
      return navigate(location.state.from);
    }
    window.location = '/';
  } catch (err) {
    throw new Error(err.response.data.message);
  }
};

export const logout = () => {
  removeToken();
  removeAdminTab();
  removeFirmId();
  removeUserId();
  removeHyvor();
  window.location = '/';
};
