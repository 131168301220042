export const initialState = {
  pageType: '',
  hasAdminPermission: null,
  hasFirmAdminPermission: null,
  currentUser: {},
  network_roles: [],
  firm_roles: [],
  certifications: [],
  specialty_categories: [],
  specialties: [],
  industries: [],
  regions: {},
  countries: [],
  cities: [],
  firms: [],
  vlpServices: [],
  firmFilters: {},
  memberFilters: {},
  desksFilters: {},
  cookies: null,
};

export const reducer = (state = initialState, action) => {
  const helper = {
    SET_PAGE_TYPE: {
      ...state,
      pageType: action.payload,
    },
    SET_ADMIN_PERMISSION_TYPE: {
      ...state,
      hasAdminPermission: action.payload,
    },
    SET_FIRM_ADMIN_PERMISSION_TYPE: {
      ...state,
      hasFirmAdminPermission: action.payload,
    },
    SET_CURRENT_USER: {
      ...state,
      currentUser: action.payload,
    },
    SET_NETWORK_ROLES: {
      ...state,
      network_roles: action.payload,
    },
    SET_FIRM_ROLES: {
      ...state,
      firm_roles: action.payload,
    },
    SET_CERTIFICATIONS: {
      ...state,
      certifications: action.payload,
    },
    SET_SPECIALTIES: {
      ...state,
      specialties: action.payload,
    },
    SET_SPECIALTY_CATEGORIES: {
      ...state,
      specialty_categories: action.payload,
    },
    SET_INDUSTRIES: {
      ...state,
      industries: action.payload,
    },
    SET_REGIONS: {
      ...state,
      regions: action.payload,
    },
    SET_COUNTRIES: {
      ...state,
      countries: action.payload,
    },
    SET_CITIES: {
      ...state,
      cities: action.payload,
    },
    SET_FIRMS: {
      ...state,
      firms: action.payload,
    },
    SET_VLP_SERVICES: {
      ...state,
      vlpServices: action.payload,
    },
    SET_FIRM_FILTERS: {
      ...state,
      firmFilters: action.payload,
    },
    SET_MEMBER_FILTERS: {
      ...state,
      memberFilters: action.payload,
    },
    SET_DESKS_FILTERS: {
      ...state,
      desksFilters: action.payload,
    },
    SET_COOKIES: {
      ...state,
      cookies: action.payload,
    },
  };
  return helper[action.type] || state;
};
