import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { login } from 'services/login';
import { required, email as isEmail } from 'common/utils/validations';
import { useStore } from 'context/Store';

export const LoginForm = ({ setCookieModal }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState('');
  const { register, handleSubmit, formState } = useForm({
    mode: 'onSubmit',
  });

  const [state] = useStore();
  const { cookies } = state;
  const { t } = useTranslation('login');
  const { t: vt } = useTranslation('validations');

  const { errors } = formState;

  const handleLogin = async params => {
    try {
      await login(params, location, navigate);
    } catch (error) {
      setLoginError(error.message);
    }
  };

  return (
    <form className="login__widget" onSubmit={handleSubmit(handleLogin)}>
      <label className="mt-10px">{t('emailAddress')}</label>
      <input
        disabled={!cookies}
        {...register('email', {
          validate: {
            required: value => required(value, vt),
            isEmail: value => isEmail(value, vt),
          },
        })}
        name="email"
        placeholder={t('emailAddress')}
        className={`form-control input__container ${errors.email ? 'form__input--error' : ''}`}
        type="email"
      />
      {errors.email && <span className="input__error">{errors.email.message}</span>}
      <label className="login__label">{t('password')}</label>
      <div className="login__input-container">
        <input
          disabled={!cookies}
          {...register('password', {
            validate: {
              required: value => required(value, vt),
            },
          })}
          name="password"
          type={showPassword ? 'text' : 'password'}
          placeholder={t('password')}
          className={`form-control input__container ${errors.password ? 'form__input--error' : ''}`}
        />
        <i
          className={`login__icon ${showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'}`}
          onClick={() => setShowPassword(!showPassword)}
        />
      </div>
      {errors.password && <span className="input__error">{errors.password.message}</span>}
      {loginError && <span className="login__error">{loginError}</span>}

      {cookies ? (
        <button disabled={!cookies} className="login__btn my-30px" type="submit">
          {t('login')}
        </button>
      ) : (
        <p onClick={() => setCookieModal(true)} className="login__btn my-20px">
          {t('loginComment')}
        </p>
      )}

      {process.env.CROWE_IS_IN_MAINTENANCE && <p className="login__comment">{t('maintenance')}</p>}
      {!process.env.CROWE_IS_IN_MAINTENANCE && (
        <p className="login__comment">{t('registerComment')}</p>
      )}
      <Link to="/register" className="login__btn login__btn--disabled">
        {t('register')}
      </Link>
    </form>
  );
};

export default LoginForm;
