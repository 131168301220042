import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import video from 'assets/videos/1-Login-1920x1080.mp4';
import { postEmail } from 'services/forgotPassword';
import { useForm } from 'react-hook-form';
import { required, email as isEmail } from 'common/utils/validations';
import { ToastContainer, toast } from 'react-toastify';
import { TOAST_CONFIG } from 'common/constants/toast';
import BouncingButton from 'common/components/Button/BouncingButton';

const ForgotPassword = () => {
  const [submitting, setSubmitting] = useState(false);

  const { t } = useTranslation('forgotPassword');
  const { t: gt } = useTranslation('global');
  const { vt } = useTranslation('validations');

  const { register, handleSubmit, formState, setValue } = useForm({
    mode: 'all',
  });

  const { errors, isValid } = formState;

  const onSubmit = async params => {
    setSubmitting(true);
    try {
      await postEmail(params);
      setValue('email', '');
      toast.success(t('emailSent'), TOAST_CONFIG);
      setSubmitting(false);
    } catch (err) {
      toast.error(gt('errorMessage'), TOAST_CONFIG);
      setSubmitting(false);
    }
  };

  useEffect(() => {
    document.title = 'Crowe Global - Forgot Password';
  }, []);

  return (
    <>
      <video className="login__video" autoPlay muted loop webkit-playsinline="true" playsInline>
        <source src={video} type="video/mp4" />
      </video>
      <div className="forgot__container">
        <h2 className="forgot__title">{t('forgotPasswordTitle')}</h2>
        <p className="forgot__subtitle"> {t('enterEmailAddr')}</p>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <label>Email</label>
          <input
            {...register('email', {
              validate: {
                required: value => required(value, vt),
                isEmail: value => isEmail(value, vt),
              },
            })}
            type="email"
            name="email"
            placeholder={t('email')}
            className={`form-control forgot__input ${errors.email ? 'forgot__input--error' : ''}`}
          />

          {errors.email && <span className="forgot__error">{errors.email.message}</span>}

          <BouncingButton
            className={`forgot__btn ${submitting || !isValid ? 'forgot__btn--disabled' : ''}`}
            loading={submitting}
            type="submit"
            disabled={submitting || !isValid}
          >
            {submitting ? 'Submitting' : 'Submit'}
          </BouncingButton>

          <div className="forgot__link-container">
            <Link className="forgot__link" to="/login">
              {t('backToLogin')}
            </Link>
          </div>
        </form>
        <ToastContainer />
      </div>
    </>
  );
};

export default ForgotPassword;
