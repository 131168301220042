import { useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { getToken } from 'common/utils/localStorage';
import { useStore } from 'context/Store';
// Component that pops up if user didn't accept new terms and privacy policy
import TosModal from 'common/components/TosModal/TosModal';
import { ToastContainer } from 'react-toastify';

const PUBLIC_ROOT = '/login';

export const AuthRoute = () => {
  const [openTos, setOpenTos] = useState(true);

  const token = getToken();

  const [state] = useStore();
  const { currentUser: user, cookies } = state;

  const location = useLocation();
  return (
    <>
      {user.id && !user.accepted_current_tos && openTos && <TosModal setOpenTos={setOpenTos} />}
      <ToastContainer />
      {token && cookies ? <Outlet /> : <Navigate to={PUBLIC_ROOT} replace state={{ from: location }} />}
    </>
  );
};

export default AuthRoute;
