import { useState, useEffect } from 'react';
import { useStore } from 'context/Store';
import CollapsibleMenu from 'common/components/CollapsibleMenu/CollapsibleMenu';
import { getMembers } from 'services/user';
import DirectoryList from 'common/components/Directory/DirectoryList';
import MemberCard from 'common/components/Directory/Cards/MemberCard';
import MemberDirectoryFilters from 'common/components/Directory/Filters/MemberDirectoryFilters';
import { useFilters } from 'hooks/useFilters';
import MemberCardSkeleton from 'common/components/Skeletons/MemberCardSkeleton';

const PAGE_SIZE = 20;

const MemberDirectory = () => {
  const [offset, setOffset] = useState(0);

  const [state, dispatch] = useStore();

  const {
    network_roles,
    firm_roles,
    specialty_categories,
    specialties,
    industries,
    memberFilters,
  } = state;

  const { items, count, loading, error, handleFilters } = useFilters(
    getMembers,
    offset,
    memberFilters
  );

  const handleSelectPage = pageNumber => {
    setOffset((pageNumber - 1) * PAGE_SIZE);
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleChange = (filterName, value) => {
    let newFilters = {};
    setOffset(0);

    if (!!filterName) {
      newFilters = { ...memberFilters, [filterName]: value?.value };
    }
    if (filterName === 'specialty_category') {
      newFilters = { ...memberFilters, specialty_category: value?.value, specialties: null };
    }
    if (filterName === 'country') {
      newFilters = { ...memberFilters, country: value?.value, city: null, firm: null };
    }
    if (filterName === 'city') {
      newFilters = { ...memberFilters, city: value?.value, firm: null };
    }
    if (
      filterName === 'industries' ||
      filterName === 'network_roles' ||
      filterName === 'firm_roles'
    ) {
      newFilters = { ...memberFilters, [filterName]: value.map(option => option.value) };
    }
    dispatch({ type: 'SET_MEMBER_FILTERS', payload: { ...newFilters } });
  };

  useEffect(() => {
    handleFilters();
  }, [offset, memberFilters]);

  return (
    <div className="mt-20px directory__list-container">
      <div className="detail__widget-container--large">
        <div className="bg-white rounded mb-20px">
          <CollapsibleMenu filterType="person">
            <MemberDirectoryFilters
              count={count}
              handleChange={handleChange}
              networkRoles={network_roles}
              firmRoles={firm_roles}
              industries={industries}
              specialtyCategories={specialty_categories}
              specialties={specialties}
              filters={memberFilters}
              error={error}
            />
          </CollapsibleMenu>
        </div>
        <DirectoryList
          skeleton={MemberCardSkeleton}
          records={items.users}
          recordComponent={MemberCard}
          recordCount={count}
          isFetching={loading}
          pageSize={PAGE_SIZE}
          offset={offset}
          onSelectPage={handleSelectPage}
          countLabelKey="memberCount"
          noResultsIcon="person"
        />
      </div>
    </div>
  );
};

export default MemberDirectory;
