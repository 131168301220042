import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getUserById } from 'services/user';
import { getFirm } from 'services/firm';
import MemberDetail from 'common/components/MemberDetail/MemberDetail';
import DirectoryHeader from 'common/components/Directory/DirectoryHeader';
import MemberDetailSkeleton from 'common/components/Skeletons/MemberDetailSkeleton';

const MemberDetailPage = () => {
  const [user, setUser] = useState(null);
  const [firmData, setFirmData] = useState({});

  const { id } = useParams();

  const handleUser = async () => {
    const currentUser = await getUserById(id);
    const currentFirm = await getFirm(currentUser?.firm_id);
    setUser(currentUser);
    setFirmData(currentFirm);
  };

  useEffect(() => {
    handleUser();
  }, []);

  return (
    <div className="detail__container">
      <DirectoryHeader />
      {!user ? <MemberDetailSkeleton /> : <MemberDetail user={user} firmData={firmData} />}
    </div>
  );
};

export default MemberDetailPage;
